import { getAnalyticsUrlParams } from 'globals/utils/url';
import {
  defaultExchangeSegmentSupport,
  defaultHistoricalConfigs,
  defaultOrderPreferences,
  defaultSettingPreferences,
  getDynamicAppConfigs,
  initDynamicAppConfigs,
} from 'habitual-analytics/constants/dynamicAppConfigs';
import { signOut } from 'habitual-analytics/formProvider/UserFormProvider/api';
import { isMobile } from 'react-device-detect';

export const getVerificationDetails = async () => {
  let isValidAccessToken = false;
  const { cacheVersion, postBackSupportedBrokers } = window?.instaConstants ?? {};

  const {
    broker: userBroker,
    broker_access_token: accessToken,
    broker_client_id: clientId,
    order_preferences: op,
    setting_preferences: sp,
    broker_raw_info: brokerExtras,
    exchange_segment_support: exchangeSegmentSupport,
    phone_number: mobileNumber,
  } = _.get(window, 'instaConstants.userDetails', {});
  const { themeVariant: theme } = _.get(window, 'instaConstants', {});
  const { broker: actualBroker, alias } = getDynamicAppConfigs()?.domainConfigs;
  const broker = alias === userBroker ? actualBroker : userBroker;

  const { userRunType, historicalDateAndTime } = getAnalyticsUrlParams();

  const brokerConfigs = {
    broker,
    brokerClientId: clientId,
    brokerClientAccessToken: accessToken,
    mobileNumber,
    brokerExtras,
  };

  initDynamicAppConfigs({
    brokerConfigs,
    orderPreferences: !_.isEmpty(op) ? op : defaultOrderPreferences,
    settingPreferences: !_.isEmpty(sp) ? sp : defaultSettingPreferences,
    historicalConfigs: {
      userRunType: userRunType || defaultHistoricalConfigs.userRunType,
      historicalDateAndTime: historicalDateAndTime || defaultHistoricalConfigs.historicalDateAndTime,
    },
    cacheVersion,
    theme,
    isMobile,
    postBackSupportedBrokers,
    exchangeSegmentSupport: _.isEmpty(exchangeSegmentSupport)
      ? defaultExchangeSegmentSupport : exchangeSegmentSupport,
  });

  const dynamicAppConfigs = getDynamicAppConfigs();
  const { brokerClientId, brokerClientAccessToken } = _.get(dynamicAppConfigs, 'brokerConfigs', {});

  const isNewLogin = _.isEmpty(dynamicAppConfigs.brokerConfigs)
   || !brokerClientId || !brokerClientAccessToken;

  if (isNewLogin) {
    return { isUserLoggedIn: false, isLoading: false, message: '' };
  }

  // check if access token is valid or not
  if (broker !== 'broker_stub') {
    isValidAccessToken = await getDynamicAppConfigs().isValidAccessToken();;
    if (!isValidAccessToken) signOut();
  } else {
    isValidAccessToken = true;
  }

  if (!isValidAccessToken) {
    return {
      isUserLoggedIn: false,
      isLoading: false,
      message: 'Access Token Expired Please Login',
    };
  }

  return {
    isUserLoggedIn: true,
    isLoading: false,
    message: '',
  };
};
