import _ from 'lodash';

import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import {
  appendParamsToUrl,
  delayPromise,
  MAX_RETRIES,
  shouldRetryRequest,
  formatRequestParams,
  isRootUrl
} from './utils';

const handleApiFailure = async (retryCount, url, params, apiResponse) => {
  const errorResponse = {
    code: 'API_FAIL',
    message: 'Request failed, please try again later.',
  };

  try {
    errorResponse.apiResponse = apiResponse ? await apiResponse?.text() : null;
  } catch (error) {
    console.error(error);
    errorResponse.apiResponse = null;
  }

  if (retryCount < MAX_RETRIES) {
    await delayPromise();
    const retryUrl = appendParamsToUrl(url, { RETRY_COUNT: retryCount });

    // Retry the API call
    return executeApiRequest(retryUrl, params, retryCount + 1);
  }

  return Promise.reject(errorResponse);
};

const handleApiResponse = async (response, url, retryCount, params) => {
  try {
    const jsonResponse = await response?.clone?.()?.json();

    if (shouldRetryRequest(jsonResponse || {})) {
      return handleApiFailure(retryCount, url, params, response);
    }

    return Promise.resolve(response);
  } catch (error) {
    console.error(error);
    return Promise.resolve(response);
  }
};

const executeApiRequest = async (url, params, retryCount) => {
  try {
    const response = await fetch(url, params);
    const isOkResponse = response.ok;

    if (isOkResponse) {
      // If response is ok, resolve the promise with response
      return handleApiResponse(response, url, retryCount, params);
    }

    // If response is not ok, retry the API call
    return handleApiFailure(retryCount, url, params, response);
  } catch (error) {
    console.error(error);
    // If API call fails, retry the API call
    return handleApiFailure(retryCount, url, params, error);
  }
};

const fetchWithoutRetries = _.curry((url, params) => executeApiRequest(
  url,
  formatRequestParams(params, url),
  MAX_RETRIES
));

const fetchWithRetries = _.curry((url, params) => {
  const cacheVersion = _.get(getDynamicAppConfigs(), 'cacheVersion', '100');
  const modifiedUrl = appendParamsToUrl(url, { cacheVersion });
  return executeApiRequest(modifiedUrl, formatRequestParams(params, modifiedUrl), 0);
});

// Exported function
export const wrappedFetch = async (url, params = {}) => {
  if (isRootUrl(url)) {
    return fetchWithRetries(url, params);
  }

  return fetchWithoutRetries(url, params);
};
