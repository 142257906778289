 
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import {
  BROKER_CONFIG,
  MonthNamesToIndex,
} from 'habitual-analytics/constants/habitual-configs';
import Instrument from 'habitual-analytics/models/instrument';
import { equitySymbolToSATradingSymbol } from 'habitual-analytics/models/instrument/factory';
import _ from 'lodash';
import moment from 'moment';
import { getUpComingExpiryDates } from '../../dateUtils/expiryDateCalculations';
import {
  getFormattedTradingSymbolObj as getFyersFormattedTradingSymbolObj,
  getPlaceOrderFormattedTradingSymbol as getFyersPlaceOrderFormattedTradingSymbol,
} from './fyers';

export const DEFAULT_SYMBOL_PATTERN =
  /([A-Z]+)(\d{2}[A-Z]{3}\d{2})([CPF]?)(\d*)/;
// NIFTY30NOV23F, BANKNIFTY04OCT23C44500, BANKNIFTY28SEP23F
export const UPSTOX_TRADING_SYMBOL_PATTERN =
  /([A-Z]+)(\d{2})?(\d{1}|[A-Z]{3})?(\d{2})?(\d+)?(CE|PE|FUT)/;
// NIFTY24DEC23000CE NIFTY241113000PE NIFTY24DEC23000FUT

const upstoxFormattedTradingSymbol = (tradingSymbol) => {
  let formattedExpiryDate;
  let formattedYear;
  const upstoxRegexMatches = tradingSymbol.match(UPSTOX_TRADING_SYMBOL_PATTERN);

  if (!upstoxRegexMatches) return equitySymbolToSATradingSymbol(tradingSymbol);

  let [, , year, month, date] = upstoxRegexMatches;

  formattedYear = moment(year, 'YY').format('YYYY');

  if (!_.parseInt(month)) {
    // if month is not a number then it is a month name
    const formattedExpiryMonth = MonthNamesToIndex[month];
    // get expiry dates for the month
    const expiryDates = getUpComingExpiryDates('M', upstoxRegexMatches?.[1]);
    // get the expiry date for the particular month
    formattedExpiryDate = _.filter(expiryDates, (date) =>
      _.includes(date, `${formattedYear}-${formattedExpiryMonth}`)
    )?.[0];
    // add the date to the trading symbol
    upstoxRegexMatches[5] = _.concat(date, upstoxRegexMatches[5]).join('');
  } else
    formattedExpiryDate = moment(
      `${formattedYear}-${month}-${date}`,
      'YYYY-M-DD'
    ).format('YYYY-MM-DD');

  return new Instrument({
    symbol: upstoxRegexMatches[1],
    expiryDate: formattedExpiryDate,
    optionType: upstoxRegexMatches[6],
    strikePrice: upstoxRegexMatches[5] || '',
  });
};

export const getFormattedTradingSymbolObject = (tradingSymbol) => {
  const { broker } = getDynamicAppConfigs().brokerConfigs;

  switch (broker) {
    case BROKER_CONFIG.aliceblue.id:
    case BROKER_CONFIG.zebu.id:
    case BROKER_CONFIG.philip_capital.id:
    case BROKER_CONFIG.tradesmart.id:
    case BROKER_CONFIG.enrich_money.id:
    case BROKER_CONFIG.goodwill.id:
    case BROKER_CONFIG.profitmart.id:
    case BROKER_CONFIG.finvasia.id:
    case BROKER_CONFIG.investright.id:
    case BROKER_CONFIG.sky_broking.id:
      const isEquity =
        _.endsWith(tradingSymbol, 'EQ') || _.includes(tradingSymbol, 'EQ');
      if (isEquity) {
        return equitySymbolToSATradingSymbol(tradingSymbol);
      }

      if (
        _.includes(tradingSymbol, 'SENSEX') ||
        _.includes(tradingSymbol, 'BANKEX')
      ) {
        return getFyersFormattedTradingSymbolObj(tradingSymbol);
      }

      const matches = tradingSymbol.match(DEFAULT_SYMBOL_PATTERN);
      const [, symbol, expiryDate, callPutOrFuture, strikePrice] = matches;
       
      const optionType =
        callPutOrFuture === 'F' ? 'FUT' : callPutOrFuture === 'C' ? 'CE' : 'PE';

      return new Instrument({
        symbol,
        expiryDate: moment(expiryDate, 'DDMMMYY').format('YYYY-MM-DD'),
        optionType,
        strikePrice: strikePrice || '',
      });
    
    case BROKER_CONFIG.fivepaisa.id:
      return getFyersFormattedTradingSymbolObj(tradingSymbol);
    case BROKER_CONFIG.maitra_commodities.id:
      const maitraTradingSymbolPattern =
        /^([A-Z]+?) (\d{2}[A-Z]{3}\d{4}?) ?(CE|PE|FUT)?(?: (\d*))?$/;
      const regexMatches = tradingSymbol.match(maitraTradingSymbolPattern);

      if (!regexMatches) return equitySymbolToSATradingSymbol(tradingSymbol);

      return new Instrument({
        symbol: regexMatches[1],
        expiryDate: moment(regexMatches[2], 'DDMMMYYYY').format('YYYY-MM-DD'),
        optionType: regexMatches[3],
        strikePrice: regexMatches[4] || '',
      });
    case BROKER_CONFIG.upstox.id:
      return upstoxFormattedTradingSymbol(tradingSymbol);

    case BROKER_CONFIG.fyers.id:
      return getFyersFormattedTradingSymbolObj(tradingSymbol);
    default:
      return new Instrument({
        symbol: tradingSymbol,
        expiryDate: '',
        optionType: '',
        strikePrice: '',
      });
  }
};

export const getPlaceOrderTradingSymbol = (tradingSymbolObj) => {
  const { broker } = getDynamicAppConfigs().brokerConfigs;

  switch (broker) {
    case BROKER_CONFIG.aliceblue.id:
    case BROKER_CONFIG.zebu.id:
    case BROKER_CONFIG.philip_capital.id:
    case BROKER_CONFIG.maitra_commodities.id:
    case BROKER_CONFIG.tradesmart.id:
    case BROKER_CONFIG.enrich_money.id:
    case BROKER_CONFIG.goodwill.id:
    case BROKER_CONFIG.profitmart.id:
    case BROKER_CONFIG.finvasia.id:
    case BROKER_CONFIG.sky_broking.id:
      if (tradingSymbolObj.getExchange() === 'BSE') {
        return getFyersPlaceOrderFormattedTradingSymbol(tradingSymbolObj);
      }
      if (tradingSymbolObj.isEquity()) {
        return `${tradingSymbolObj.toString()}-EQ`;
      }
      if (tradingSymbolObj.isFuture()) {
        return `${tradingSymbolObj.symbol}${moment(
          tradingSymbolObj.expiryDate
        ).format('DDMMMYY')}F`.toUpperCase();
      }
      return `${tradingSymbolObj.symbol}${moment(
        tradingSymbolObj.expiryDate
      ).format('DDMMMYY')}${tradingSymbolObj.optionType === 'CE' ? 'C' : 'P'}${
        tradingSymbolObj.strikePrice
      }`.toUpperCase();
    case BROKER_CONFIG.fyers.id:
      return getFyersPlaceOrderFormattedTradingSymbol(tradingSymbolObj);
    default:
      return tradingSymbolObj.toString();
  }
};

export const getDefaultProductCode = (pCode, tradingSymbolObj = {}) => {
  const { broker } = getDynamicAppConfigs().brokerConfigs;
  if (
    broker === BROKER_CONFIG.paytm_money.id ||
    broker === BROKER_CONFIG.axis_direct.id ||
    broker === BROKER_CONFIG.fivepaisa.id
  ) {
    switch (_.toLower(pCode)) {
      case 'intraday':
        return 'MIS';
      case 'overnight':
      case 'delivery':
        return tradingSymbolObj.isEquity() ? 'CNC' : 'NRML';
      default:
        return pCode;
    }
  } else if (
    broker !== BROKER_CONFIG?.prabhudas?.id &&
    broker !== BROKER_CONFIG?.upstox?.id &&
    broker !== BROKER_CONFIG?.religare?.id &&
    broker !== BROKER_CONFIG?.iifl_nova?.id 
  ) {    
    switch (_.toLower(pCode)) {
      case 'delivery':
      case 'c':
      case 'cnc':
        return 'CNC';
      case 'carryforward':
      case 'overnight':
      case 'm':
      case 'nrml':
      case 'margin':
        return 'NRML';
      case 'intraday':
      case 'i':
      case 'mis':
        return 'MIS';
      default:
        return pCode;
    }
  } else {  
    switch (_.toLower(pCode)) {
      case 'delivery':
      case 'd':
        return tradingSymbolObj.isEquity() ? 'CNC' : 'NRML';
      case 'intraday':
      case 'i':
        return 'MIS';
      default:
        return pCode;
    }
  }
};